<template>
  <v-app id="app" app>
    <TheAppBar />
    <v-main>
      <router-view />
    </v-main>
    <TheBottomNavigation/>
  </v-app>
</template>

<script>

  import TheAppBar from "@/components/TheAppBar";
  import TheBottomNavigation from "@/components/TheBottomNavigation.vue";

  export default {
    components: {
      TheAppBar,
      TheBottomNavigation
     },
  };
</script>

